.App {
  text-align: center;
  max-width: 800px;
  margin: auto;
}

h3 {
  margin-top: 20px !important;
}

.App-logo {
  height: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
    filter: brightness(0) invert(1);
  }
}

.App-header {
  background-color: #282c34;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-right: 15px;
}

.App-why {
  /*padding: 0;*/
}

.App-why-logo {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  height: auto;
}

.App-why .accordion-button {
  background: #d6a206 !important;
  color: white !important;
  font-weight: bold;
}

.App-why .accordion-button.collapsed {
  background: #ffc108 !important;
  color: white !important;
  font-weight: bold;
}

.App-why .disclaimer {
  font-size: 11px;
  line-height: 13px;
  font-style: italic;
}

.App-step-accordion {
  margin-top: 20px;
}

.App-step-accordion .accordion-button {
  background: rgb(16 129 156) !important;
  color: white !important;
  font-weight: bold;
}

.App-step-accordion .accordion-button svg {
  margin-right: 4px;
  margin-top: -2px;
}

button.accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  filter: brightness(0) invert(1);
}

.App-step-accordion .accordion-button.collapsed {
  background: rgb(29 172 207);
  color: white !important;
  font-weight: bold;
}

h2.power.accordion-header button.accordion-button {
  background: repeating-linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2) 10px,
          rgba(0, 0, 0, 0.3) 10px,
          rgba(0, 0, 0, 0.3) 20px
  ), rgb(29 172 207) !important;
}

.App-step-body {
  width: 100%;
  padding: 0 !important;
}

.App-calculator-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.App-calculator-title:before {
  content: "";
  display: inline-block;
  width: 35%;
  background: rgba(0,0,0,0.1);
  height: 1px;
}
.App-calculator-title:after {
  content: "";
  display: inline-block;
  width: 35%;
  background: rgba(0,0,0,0.1);
  height: 1px;
}

.App-notes {
  margin-bottom: 15px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.App-link {
  color: #61dafb;
}

.why-body {
  text-align: justify;
}

.Well {
  width: 750px;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  color: #333;
}

.buttons-calc {
  padding-top: 10px;
}

.buttons-calc button {
  width: 100%;
  color: white;
}

.col-sm-12 > p {
  text-align: justify;
}

.form-label {
  width: 100%;
  margin-top: 5px;
  font-weight: bold;
}

.form-horizontal {
  background: rgb(29 172 207);
  border-radius: 3px;
  padding: 0 15px;
  margin-top: 0;
  color: white;
}

.results {
  background: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding: 1rem;
  color: #6c757d;
}

.line-results {
  text-align: left;
  width: 100%;
}

.line-results:last-of-type:before {
  content: "";
  width: 100%;
  display: block;
  margin: 5px 0;
  border-top: 1px solid rgba(0,0,0,0.3);
}

.g-2:before {
  content: "";
  width: 100%;
  display: block;
}

.g-2:after {
  content: "";
  margin-bottom: 21px;
  display: block;
  width: 100%;
}

.helper {
  padding-left: 3px;
  display: inline-block;
  float: right;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
