
.App-switch-poweruser h6 {
    margin-top: 20px;
}

.App-switch-poweruser .react-switch-selector-wrapper {
    margin: 0 auto 20px auto;
    font-size: 13px;
    text-align: left;
    background: #10819C;
    max-width: 500px;
    height: 55px;
    border: 6px solid #10819C;
    border-radius: 26px;
}
.App-switch-poweruser .react-switch-selector-wrapper:before {
    border: 2px solid #fff;
    border-radius: 20px;
}

.App-switch-poweruser .react-switch-selector-option:first-of-type {
    /*padding-right: 13px;*/
}

.App-switch-poweruser .react-switch-selector-wrapper label.react-switch-selector-option-label {
    cursor: pointer;
    padding-right: 13px;
}

.App-switch-poweruser .react-switch-selector-wrapper label.react-switch-selector-option-label span {
    padding: 5px 0;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
}
