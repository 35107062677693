.App-Data-container .form-horizontal {
    min-height: 100px;
}

.App-Data-container .form-horizontal.App-Data-form .col.App-Data-version {
    padding: 0;
    display: flex;
    justify-content: center;
    font-size: 15px;
}

.App-Data {
    display: flex;
    height: 38px;
    justify-content: space-between;
}

.App-Data-form {
    padding: 1px 15px 15px 15px;
}

.App-Data-form .helper {
    padding-right: 10px;
    padding-top: 4px;
}

.App-Data-col {
    display: flex;
    align-items: center;
}

.App-Data-col label {
    padding-left: 30px;
}

.row>* {
    padding-right: 0 !important;
}

@media (max-width: 800px) {
    .App-Data-container .form-horizontal {
        min-height: 130px;
    }

    .App-Data {
        display: flex;
        height: 62px;
    }
}

