.App-Mppt-container {
    margin-top: 20px;
}

.App-Mppt-container-wrapper {
    background: #1daccf;
    border-radius: 3px;
    padding: 10px 15px;
    margin-top: 0;
    color: white;
}

.App-Mppt-container-wrapper h5 {
    display: flex;
    align-items: center;
    margin: -10px -15px 10px -15px;
    background: #ffc108;
    padding: 15px 0 16px 20px;
    font-size: 1.15rem;
    font-weight: bold;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-align: left;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}

.App-Mppt-container-wrapper.power h5 {
    background: repeating-linear-gradient(45deg,rgba(0, 0, 0, 0.01),rgba(0, 0, 0, 0.01) 10px,rgba(0, 0, 0, 0.1) 10px,rgba(0, 0, 0, 0.1) 20px), #FFC108;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}

.App-Mppt-container-row {
    margin: 0 !important;
    display: flex;
}

.App-Mppt-container.container .App-Mppt-col img {
    width: 100%;
    max-width: 100px;
}

.App-Mppt-col.col {
    max-width: 30%;
    padding-right: 12px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-Mppt-col:last-of-type {
    max-width: 70%;
    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 4px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: stretch;
    padding-left: 12px;
}

.App-Mppt-col:last-of-type label {
    font-weight: bold;
    margin-right: 5px;
}

.App-Mppt-col-spec-detail {
    border-bottom: 1px solid rgba(255,255,255,0.5);
    padding: 5px 0;
}
.App-Mppt-col-spec-detail:last-of-type {
    border-bottom: none;
}

.App-Mppt-col-spec-detail span {
    display: inline-block;
    padding: 10px;
}

.App-Mppt-col-spec-detail.ccr label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.App-Mppt-col-spec-detail.ccr .helper {
    width: 16px;
    padding: 0;
}

.App-Mppt-col-spec-detail.panel span, .App-Mppt-col-spec-detail.ccr span {
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.2);
}

.App-Mppt-col-spec-detail.panel span:last-of-type, .App-Mppt-col-spec-detail.ccr span:last-of-type {
    border-bottom: none;
}

@media (max-width: 375px) {
    .App-Mppt-container-wrapper h5 {
        font-size: 0.9rem;
    }
}