.App-Panel label.App-Data-total-watt.form-label {
    text-align: left;
}

.App-Panel .App-Panel-form {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
}

.App-Panel .App-Panel-form .col:first-of-type {
    padding-left: 0;
}

.App-Panel .App-Panel-form input[type='number'] {
    padding: .375rem .5rem;
}

.App-Panel .list-group-item {
    cursor: pointer;
    flex-grow: 1;
}

.App-Panel .list-group-item:hover {
    color: #fff;
    background-color: #ffca2c;
    border-color: #ffca2c;
}

.App-Panel .list-group-item.active {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
}

.App-Panel .list-group-horizontal {
    width: 100%;
    align-items: flex-end;
    margin-bottom: 20px;
}

.App-Panel .form-select {
    padding: .375rem 2.25rem .375rem .5rem;
}

.App-Panel-img {
    align-items: center;
}

.App-Panel-img.row .col {
    position: relative;

    width: 100%;
    margin: 0 10px;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-Panel-img.row .col img {
    width: 100%;
    padding: 0;
    margin-top: 0;
}

.App-Panel-img .wrap-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.App-Panel-img .wrap-parallel {
    position: relative;
    padding-bottom: 10px;
}

.App-Panel-img .wrap-parallel-last {
    border-left: 2px solid #d55858;
    border-right: 2px solid black;
}

.App-Panel-img .wrap-parallel:before {
    content: "";
    height: 100%;
    width: 2px;
    background: #2b2a2a;
    position: absolute;
    right: -1px;
    top: 0;
}

.App-Panel-img .wrap-parallel:after {
    content: "";
    height: 100%;
    width: 2px;
    background: #d55858;
    position: absolute;
    left: -2px;
    top: 0;
}

.App-Panel-img .img-cell {
    max-width: 40px;
}

.App-Panel-img .img-cell.Parallel {
    border-top: 1px solid #d55858;
}

.App-Panel-img .wrap-other .img-cell.Serial {
    border-bottom: 2px solid #2b2a2a;
    padding-bottom: 3px;

    width: 100%;
    margin-top: 4px;
}

.App-Panel-img .wrap-other .img-cell.Serial:last-of-type {
    border-right: 2px solid #2b2a2a;
    box-sizing: content-box;
}

.App-Panel-img .row input.form-control {
    max-width: 70px;
}

.main-wrap {
    position: relative;
}
.App-Panel-img .panel-watt {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    width: auto;
    padding: 0 5px;
    background: #10819c;
    border: 2px solid rgba(0,0,0,0);
    border-radius: 4px;
    font-size: 14px;
    text-shadow: 0 1px 1px #0b5b6e;
}

@media (max-width: 800px) {
    .App-Panel label.App-Data-total-watt.form-label {
        font-size: 11px;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 0.5rem;
    }

    .App-Panel .App-Panel-form {
        margin-bottom: 0;
    }

    .App-Panel-form.row:last-of-type {
        margin-bottom: 10px;
    }

}