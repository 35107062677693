.App-Panel-temp-group {
    display: flex;
}

.App-Panel-temp-group input.form-control {
    border-radius: 3px 0 0 3px;
}

.App-Panel-temp-group select {
    border: 1px solid #ced4da;
    border-left: none;
    border-radius: 0 3px 3px 0px;
    background-color: #dbdada;
    max-width: 70px;
}

@media (max-width: 414px) {
    .App-Panel-temp-group input.form-control {
        padding: .375rem .2rem !important;;
    }

    .App-Panel-temp-group select {
        background-position-x: 99%;
        padding: 0.1rem 0.3rem 0.1rem 0.1rem !important;
        font-size: 14px !important;
        text-align: center;
        max-width: 50px;
    }
}

@media (max-width: 414px) {
    .App-Panel-temp-group select {
        text-align: left;
        padding: 0.1rem 0.5rem 0.1rem 0.6rem !important;
    }
}