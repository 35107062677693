.App-BatteryVolt-container .form-horizontal {
    min-height: 100px;
}

.accordion-button span {
    font-size: 12px;
    margin-top: 1px;
    margin-left: 3px;
    font-style: italic;
}

.App-BatteryVolt {
    display: flex;
    height: 68px;
    justify-content: space-between;
}

.App-BatteryVolt-average {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: center;
    position: relative;
}

.App-BatteryVolt-img {
    margin-top: 25px;
}

.App-BatteryVolt-average span {
    margin-right: 5px;
    position: absolute;
    right: 0;
}

.App-BatteryVolt-average .btn-group button {
    background: #ffc108 !important;
    color: white !important;
    font-weight: bold;
    border: 1px solid rgba(255, 255 ,255 ,0.5);
}

.App-BatteryVolt-average .btn-group button.active {
    background: #C99E28 !important;
}

.App-BatteryVolt-average .form-control {
    width: 76px;
}

.App-BatteryVolt-img {
    padding: 0.5rem;
    position: relative;
}

.App-BatteryVolt-img img {
    max-width: 140px;
}

.App-BatteryVolt-img .App-BatteryVolt-img-value {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 800px) {
    .App-BatteryVolt-container .form-horizontal {
        min-height: 130px;
    }

    .App-BatteryVolt {
        display: flex;
        height: 62px;
    }

    .App-BatteryVolt-average {
        margin-top: 10px;
    }

    .App-BatteryVolt-img {
        margin-top: 0;
    }
}

@media (max-width: 320px) {
    .App-BatteryVolt-average span {
        margin-right: -7px;
    }
}

.App-BatteryVolt-img-value {
    display: flex;
    align-items: flex-start;
}
.App-BatteryVolt-img-value p {
    font-size: 24px;
    font-weight: 900;
    transform: scale(0.9, 1) rotatey(-10deg) translatey(0px);
    text-shadow: 0 0 #fff;
    transform-origin: 50% 100%;
    color: #2F3642;
    position: relative;
    top: 10%;
    transform-style: preserve-3d;
    opacity: 1;
}

.App-BatteryVolt-img-value p.active {
    animation: 0.5s scaling cubic-bezier(0.45, 0, 0.55, 1);
}
.App-BatteryVolt-img-value p.active:nth-child(2) {
    animation: 0.5s scalingsecond cubic-bezier(0.45, 0, 0.55, 1);
}

@keyframes scaling {
    0% {
        opacity: 0;
        text-shadow: -2px 0 #fff, 2px 0 #fff, 0 2px #fff, 0 -2px #fff;
        transform: scale(1.5, 2) translatey(6px) translatex(-8px);
    }
    50% {
        /*opacity: 1;*/
    }
    90% {
        opacity: 1;
        transform: scale(0.9, 1) rotatey(-5deg);
        text-shadow: 0 0 #fff;
    }
}

@keyframes scalingsecond {
    0% {
        opacity: 0;
        text-shadow: -2px 0 #fff, 2px 0 #fff, 0 2px #fff, 0 -2px #fff;
        transform: scale(1.5, 2) translatey(6px) translatex(8px);
    }
    50% {
        /*opacity: 1;*/
    }
    90% {
        opacity: 1;
        transform: scale(0.9, 1) rotatey(-5deg);
        text-shadow: 0 0 #fff;
    }
}

@keyframes jump {
    20% {
        transform: translatey(0) scale(0);
        /*opacity: 0;*/
    }
    40% {
        transform: translatey(-50px) scale(1);
        /*opacity: 1;*/
    }
    60% {
        transform: translatey(0) scale(0);
        /*opacity: 0;*/
    }
    80% {
        transform: translatey(-50px) scale(1);
        /*opacity: 1;*/
    }
}

